import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeleteComponent } from '../../../../modules/shared/dialogs/delete/delete.component';
import { MatDialog } from '@angular/material/dialog';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { animate, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';
import { FormComponent } from '../../../../modules/forms/components/form/form.component';
import { FormConfig } from '../../../../modules/forms/models/form-config.model';

@Component({
    selector: 'elias-editor-item-custom',
    templateUrl: './item-custom.component.html',
    styleUrls: ['./item-custom.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
            ]),
            transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(100%)' }))]),
        ]),
    ],
})
export class ItemCustomComponent implements OnInit {
    @Input() config: FormConfig = { elements: [] };
    @Input() data;
    @Input() isDelete = true;
    @Input() source = '';
    @Input() type = '';

    @Output() delete = new EventEmitter<any>();
    @Output() save = new EventEmitter<any>();

    loaded$ = new BehaviorSubject<boolean>(true);

    isSaving = false;
    newData;

    @ViewChild('form') form: FormComponent;

    constructor(public dialog: MatDialog, private sideNavService: SideNavService) {}

    ngOnInit() {
        this.newData = _.cloneDeep(this.data);
        if (this.type === 'keyfigure' && this.source === 'add') {
            this.newData.title = '';
            this.newData.number = '';
            this.newData.unit = '';
            this.newData.description = '';
        }
    }

    remove() {
        const modalRef = this.dialog.open(DeleteComponent, {
            data: {
                type: 'item',
                requireExplicitConfirmation: false,
            },
        });

        modalRef.afterClosed().subscribe((remove) => {
            if (remove) {
                this.delete.emit(this.data);
            }
        });
    }

    onFormSubmitted(props) {
        this.isSaving = true;
        this.save.emit(props);
    }

    close() {
        this.sideNavService.close();
    }
}
