import { ActiveState, EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { CommentNormalized } from '../models/comment.model';
import { CommentStatistics } from '../models/comment-statistics.model';
import { SortingOptions } from '../../../modules/shared/models/sorting-options.model';
import { CommentTargetType } from '../enums/comment-target-type.enum';

export interface CommentsTarget {
    id: ID;
    type: CommentTargetType;
}

export interface CommentsState extends EntityState<CommentNormalized>, ActiveState {
    loaded: boolean;
    target: CommentsTarget | null;
    statistics: CommentStatistics;
    loadingNextPage: boolean;
    totalNumberOfPages: number;
    currentPage: number;
    hasNextPage: boolean;
    totalDataLength: number;
    ui: {
        filters: {
            resolvedAt: string | null;
            createdBy: string | null;
        };
        sorting: SortingOptions;
        page: number | null;
    };
}

export function createInitialState(): CommentsState {
    return {
        active: null,
        totalNumberOfPages: 0,
        totalDataLength: 0,
        currentPage: 1,
        hasNextPage: true,
        loaded: false,
        loadingNextPage: false,
        target: null,
        statistics: {
            total: 0,
            resolved: 0,
        },
        ui: {
            filters: {
                resolvedAt: null,
                createdBy: null,
            },
            sorting: {
                property: null,
                direction: null,
            },
            page: null,
        },
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Comments' })
export class CommentsStore extends EntityStore<CommentsState, CommentNormalized> {
    constructor() {
        super(createInitialState());
    }

    public setLoadingNextPage(loadingNextPage: boolean): void {
        this.update({ loadingNextPage, loading: loadingNextPage });
    }
}

export type CommentFilterTypes = keyof CommentsState['ui']['filters'];
