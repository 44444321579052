import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetLinksGenerator } from './generators/asset-links.generator';
import { SharedStorageLinksGenerator } from './generators/shared-storage-links.generator';
import { PublicationGroupLinksGenerator } from './generators/publication-group-links.generator';
import { NodeCommentLinksGenerator } from './generators/node-comment-links.generator';
import { SectionCommentLinksGenerator } from './generators/section-comment-links.generator';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    exports: [],
    providers: [
        AssetLinksGenerator,
        PublicationGroupLinksGenerator,
        SharedStorageLinksGenerator,
        NodeCommentLinksGenerator,
        SectionCommentLinksGenerator,
    ],
})
export class LinksModule {}
