import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Node } from '../../models/node/node.model';
import { Injectable } from '@angular/core';

export interface NodesState extends EntityState<Node> {
    loaded: boolean;
    loadedNodesForSectionId: string;
    selectedNode?: Node;
    scrollToNodeId?: string;
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Nodes', resettable: true })
export class NodesStore extends EntityStore<NodesState, Node> {
    constructor() {
        super({
            loaded: false,
            loadedNodesForSectionId: '',
            selectedNode: undefined,
            scrollToNodeId: undefined,
        });
    }
}
