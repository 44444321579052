<div class="container property-editor-container">
    <ng-container *ngIf="loaded$ | async">
        <div class="form-data">
            <div class="form-title">
                <elias-shared-module-title
                    title="{{ 'propertyEditor.widgetTitle' | translate }}"
                    [buttonFlexLayout]="'end'"
                    value="itemCustom">
                    <button *ngIf="isDelete" mat-icon-button [matMenuTriggerFor]="menu" class="menu-button-icon">
                        <i class="ei ei-more-vert"></i>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="remove()">
                            <i class="ei ei-trash"></i>{{ 'actions.delete' | translate }}
                        </button>
                    </mat-menu>
                </elias-shared-module-title>
            </div>
            <div>
                <elias-form
                    [formConfig]="config"
                    translationKey="propertyEditor"
                    [data]="newData"
                    [hideButtons]="true"
                    (formSubmitted)="onFormSubmitted($event)"
                    #form></elias-form>
            </div>
        </div>

        <div fxLayout="row" class="action-buttons" fxLayoutGap="10%" *ngIf="form.canSave()" [@slideInOut]>
            <button
                color="accent"
                class="btn-save"
                mat-flat-button
                [disabled]="!form.canSave() || isSaving"
                (click)="form.onSubmit()">
                <ng-container *ngIf="!isSaving">
                    {{ 'actions.save' | translate }}
                </ng-container>
                <ng-container *ngIf="isSaving">
                    <mat-spinner [diameter]="15" color="accent"></mat-spinner>
                </ng-container>
            </button>
            <button mat-button (click)="close()">{{ 'actions.cancel' | translate }}</button>
            <div class="changes" *ngIf="form.isDirty() && form.getNumberOfChanges() === 1">
                {{ form.getNumberOfChanges() }} {{ 'shared.change' | translate }}
            </div>
            <div class="changes" *ngIf="form.isDirty() && form.getNumberOfChanges() > 1">
                {{ form.getNumberOfChanges() }} {{ 'shared.changes' | translate }}
            </div>
        </div>
    </ng-container>
</div>
