import { Injectable } from '@angular/core';
import { LinksGeneratorRegistry } from '../generators/links-generator.registry';
import { LinksProviderInterface } from '../interfaces/links-provider.interface';

@Injectable({
    providedIn: 'root',
})
export abstract class LinksService {
    public generate(context: any): any[] | null {
        const provider = this.createLinksProvider(context);
        if (!provider) {
            return null;
        }

        for (const generator of LinksGeneratorRegistry.getGenerators()) {
            if (generator.canGenerate(provider)) {
                return generator.generate(provider);
            }
        }

        return null;
    }

    public abstract createLinksProvider(context: any): LinksProviderInterface | null;
}
