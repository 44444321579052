import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { NodesState, NodesStore } from './nodes.store';
import { Node } from '../../models/node/node.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
@QueryConfig({
    sortBy: 'position',
    sortByOrder: Order.ASC, // Order.DESC
})
export class NodesQuery extends QueryEntity<NodesState, Node> {
    constructor(protected override store: NodesStore) {
        super(store);
    }
}
