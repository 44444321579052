import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { EditorComponent } from './pages/editor/editor.component';
import { DocumentComponent } from './pages/document/document.component';
import * as fromGuards from './guards';
import { EditorDocumentResolver } from './resolvers/editor-document.resolver';
import { Roles } from '../../modules/shared/enums/roles.enum';
import { EditorResolver } from './resolvers/editor.resolver';
import { EditorBreadcrumbResolver } from './resolvers/editor-breadcrumb.resolver';

// TODO Protect routes according to rights on publication (only editors and chief editors)
const routes: Route[] = [
    {
        path: '',
        component: EditorComponent,
        data: {
            expectedRole: Roles.ROLE_EDITOR,
        },
        resolve: {
            dataResolver: EditorResolver,
            breadcrumb: EditorBreadcrumbResolver,
        },
        children: [
            {
                path: ':sectionId',
                canActivate: [fromGuards.SectionLockGuard, fromGuards.ChannelGuard],
                resolve: {
                    dataResolver: EditorDocumentResolver,
                },
                component: DocumentComponent,
                data: {
                    expectedRole: Roles.ROLE_EDITOR,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [...fromGuards.guards, EditorDocumentResolver, EditorResolver],
})
export class EditorRoutingModule {}
