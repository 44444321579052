import { Publication } from '../../models/publication.model';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface PublicationsState extends EntityState<Publication>, ActiveState {
    totalNumberOfPages: number;
    currentPage: number;
    totalDataLength: number;
    loaded: boolean;
}

const initialState = {
    totalNumberOfPages: 0,
    currentPage: 1,
    totalDataLength: 0,
    loaded: false,
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Publications', resettable: true })
export class PublicationsStore extends EntityStore<PublicationsState, Publication> {
    constructor() {
        super(initialState);
    }
}
