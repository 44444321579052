import { Injectable } from '@angular/core';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { SaveService } from '../save.service';
import { SectionsQuery } from '../../state/sections/sections.query';
import { PropertyEditorComponent } from '../../components/property-editor/property-editor.component';

@Injectable({
    providedIn: 'root',
})
export class SectionSettingsSidenavService {
    constructor(
        private saveService: SaveService,
        private sideNavService: SideNavService,
        private sectionsQuery: SectionsQuery
    ) {}

    public open(sectionId?: string): void {
        sectionId ??= this.sectionsQuery.getActiveId();

        this.saveService.saveNode(true).subscribe(async () => {
            const inputs = {
                sectionOrNodeType: 'section',
                sectionId: sectionId,
                nodeId: sectionId,
            };

            const outputs = {};
            await this.sideNavService.setComponent(PropertyEditorComponent, inputs, outputs);
        });
    }
}
