import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Content, Editor } from '@tiptap/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommentEditorAction } from '../../models/comment-editor-action.model';
import { TiptapStarterKitService } from '../../services/tiptap/tiptap-starter-kit.service';
import { TiptapPlaceholderService } from '../../services/tiptap/tiptap-placeholder.service';
import { TiptapKeyBindingsService } from '../../services/tiptap/tiptap-key-bindings.service';
import { TiptapMentionService } from '../../services/tiptap/tiptap-mention.service';
import { CommentEditorOutputDto } from '../../models/comment-editor-output-dto.model';

@Component({
    selector: 'elias-comment-editor',
    templateUrl: './comment-editor.component.html',
    styleUrls: ['./comment-editor.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
            ]),
            transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(100%)' }))]),
        ]),
    ],
    providers: [{ provide: TiptapKeyBindingsService }], // Provide fresh instance for each comment editor
})
export class CommentEditorComponent implements AfterViewInit, OnDestroy, OnChanges {
    @HostBinding('class.has-changes') hasChangesHostClass = false;
    @ViewChild('commentEditor') commentEditorRef?: ElementRef;

    @Input() action: CommentEditorAction = 'create';
    @Input() initialContent: string = '';

    @Output() commentCancelled = new EventEmitter();
    @Output() commentDraftUpdated = new EventEmitter<CommentEditorOutputDto>();
    @Output() commentSubmitted = new EventEmitter<CommentEditorOutputDto>();

    public hasContentToSubmit$: Observable<boolean>;

    private editor?: Editor;
    private contentUpdate$ = new BehaviorSubject(true);
    private destroyed$ = new ReplaySubject<void>(1);

    constructor(
        private tiptapKeyBindings: TiptapKeyBindingsService,
        private tiptapMention: TiptapMentionService,
        private tiptapPlaceholder: TiptapPlaceholderService,
        private tiptapStarterKit: TiptapStarterKitService,
        private viewContainerRef: ViewContainerRef
    ) {
        this.hasContentToSubmit$ = this.contentUpdate$.pipe(
            debounceTime(100),
            map(() => !this.isEmpty()),
            tap((hasChanged) => {
                this.hasChangesHostClass = hasChanged;
            })
        );
    }

    ngAfterViewInit() {
        this.editor = new Editor({
            element: this.commentEditorRef?.nativeElement,
            extensions: [
                this.tiptapStarterKit.getConfiguration(),
                this.tiptapPlaceholder.getConfiguration(() => this.action),
                this.tiptapKeyBindings.getConfiguration(),
                this.tiptapMention.getConfiguration(this.viewContainerRef),
            ],
            content: this.initialContent,
            autofocus: 'end',
            onUpdate: () => {
                this.onDraftUpdate();
                this.contentUpdate$.next(true);
            },
        });

        this.tiptapKeyBindings
            .getKeyBindingObservable('Mod-Enter')
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.submitComment();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['action']) {
            this.focus();
        }
    }

    public cancel(): void {
        this.commentCancelled.emit();
    }

    public submitComment(): void {
        if (!this.editor || this.isEmpty()) {
            return;
        }

        const outputDto = new CommentEditorOutputDto(
            this.editor.getHTML(),
            this.tiptapMention.getMentionedUsers(this.editor),
            this.tiptapMention.getMentionedGroups(this.editor)
        );
        this.commentSubmitted.emit(outputDto);

        this.reset();
    }

    public setContent(content: Content): void {
        this.editor?.commands.setContent(content, true);
    }

    public reset(): void {
        this.editor?.commands.clearContent(true);
        this.focus();
    }

    public focus(): void {
        this.editor?.commands.focus();
    }

    private isEmpty(): boolean {
        if (!this.editor) {
            return true;
        }

        if (this.editor.isEmpty) {
            return true;
        }

        // Get all the text in the document and check if it only consists of spaces.
        const textContent = this.editor.getText();
        return textContent.trim() === '';
    }

    private onDraftUpdate(): void {
        if (!this.editor) {
            return;
        }

        const outputDto = new CommentEditorOutputDto(
            this.editor.getHTML(),
            this.tiptapMention.getMentionedUsers(this.editor),
            this.tiptapMention.getMentionedGroups(this.editor)
        );

        this.commentDraftUpdated.emit(outputDto);
    }

    ngOnDestroy() {
        this.editor?.off('update');
        this.editor?.destroy();

        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
