import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class DayjsService {
    constructor(private translateService: TranslateService) {
        dayjs.extend(relativeTime);
    }

    fromNow(value: Date): string {
        const currentLocale = this.getFormattedCurrentLocale(this.translateService.currentLang);
        dayjs.locale(currentLocale);

        return dayjs(value).fromNow();
    }

    private getFormattedCurrentLocale(locale: string): string {
        return locale.replace('_', '-');
    }
}
