import { AfterViewInit, Component, Input } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { Observable } from 'rxjs';
import { Node } from '../../../models/node/node.model';
import { NewLineToBreakPipe } from '../../../../../modules/shared/pipes/newLineToBreak.pipe';
import { PropertyEditorComponent } from '../../property-editor/property-editor.component';
import { SaveService } from '../../../services/save.service';
import { SectionsQuery } from '../../../state/sections/sections.query';
import { SideNavService } from '../../../../../modules/core/services/side-nav.service';
import { PublicationsQuery } from '../../../../../publication/state/publications/publications.query';
import { ExcelTableNode } from '../../../models/node/excel-table-node.model';
import { NodeType } from '../../../models/node/node-type.model';

@Component({
    selector: 'elias-editor-display-excel-table',
    templateUrl: './display-excel-table.component.html',
    styleUrls: ['display-excel-table.component.scss'],
})
export class DisplayExcelTableComponent implements NodeRepresentation, AfterViewInit {
    @Input() config!: { type: NodeType; settings?: any };
    @Input() content!: string;
    @Input() node!: ExcelTableNode;
    @Input() nodeViewModel!: Observable<Node>;

    get renderedContent(): string | undefined {
        return this.node.renderedContent;
    }

    get hasRenderedContent(): boolean {
        return !!this.renderedContent;
    }

    get hasData(): boolean {
        return !!this.node.excelWorksheet;
    }

    constructor(
        private newLineToBreakPipe: NewLineToBreakPipe,
        private saveService: SaveService,
        private sectionsQuery: SectionsQuery,
        private publicationsQuery: PublicationsQuery,
        private sideNavService: SideNavService
    ) {}

    ngAfterViewInit() {
        if (!this.hasData && this.node.editing) {
            this.saveService.saveNode(true).subscribe(() => {
                const inputs = {
                    sectionOrNodeType: 'node',
                    sectionId: this.sectionsQuery.getActiveId(),
                    nodeId: this.node.id,
                };

                const outputs = {};
                this.sideNavService.setComponent(PropertyEditorComponent, inputs, outputs);
            });
        }
    }
}
